import request from '@/api/request.js'

/**
 * @Description: 月台
 */

// 分页查询
export function pageWmsPlatform(data) {
    return request({
        url: '/api/wmsPlatform/page',
        method: 'POST',
        data
    })
}

// 搜索过滤
export function listWmsPlatform(data) {
    return request({
        url: '/api/wmsPlatform/list',
        method: 'POST',
        data
    })
}

// 查看详情
export function detailWmsPlatform(data) {
    return request({
        url: '/api/wmsPlatform/detail',
        method: 'POST',
        data
    })
}

// 添加
export function addWmsPlatform(data) {
    return request({
        url: '/api/wmsPlatform/add',
        method: 'POST',
        data
    })
}

// 编辑
export function editWmsPlatform(data) {
    return request({
        url: '/api/wmsPlatform/edit',
        method: 'POST',
        data
    })
}

// 删除
export function delWmsPlatform(data) {
    return request({
        url: '/api/wmsPlatform/delete',
        method: 'POST',
        data
    })
}

// 批量删除
export function batchDeleteWmsPlatform(data) {
    return request({
        url: '/api/wmsPlatform/batchDelete',
        method: 'POST',
        data
    })
}

